<template>
  <div>
    <!-- Components -->
    <darkBackground />
    <editUserNickname :name="me?.userData?.data?.attributes?.nickname" />
    <updateUserNameMutation />
    <infoOverlay text='Du musst den Datenschutzbestimmungen zustimmen um fortzufahren.' index=1 />
    <!-- Components -->

    <div class="w-full min-h-screen md:h-screen md:overflow-hidden flex flex-row text-new-black font-visby">

      <!-- Left -->
      <div class="hidden md:block w-1/2 h-screen bg-white bg-center bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' + require(`../../assets/images/${imagePath}`) + ')' }">
        <div class="w-full h-full bg-black opacity-5"></div>
      </div>

      <!-- Right -->
      <div class="w-full md:w-1/2 p-5 h-auto md:overflow-auto md:p-12 mt-5 md:mt-0 bg-new-yellow font-visby text-new-black select-none mb-36" :class="{'pt-safe' : parseInt(sat) !== 0}">

        <div class="mt-6 font-visby font-bold text-3xl">Onboarding</div>

        <transition @enter="fadeIn" @leave="fadeOut" :css="false" mode="out-in">

        <!-- Datenschutz -->
        <div v-if="step === 1">
          <div class="text-xl">Datenschutz</div>

          <tipTap :content="dataPolicy.data.attributes.Text" />

          <div class="w-full bg-white rounded-xl shadow-new mt-5 border border-new-yellow2">
            <setting label='Datenschutz aktzeptieren' action='toggle' storeContainer='auth' storeID='toggleDataPolicy' storeWrapper='settings' />
          </div>
        </div>
        <!-- Datenschutz -->

        <!-- Benachrichtigungen -->
        <div v-else-if="step === 2">
          <div class="text-xl">Benachrichtigungen</div>

          <div class="bg-white rounded-xl shadow-new p-3 border border-new-yellow2 mt-5 text-base">
            Dürfen wir dir Push-Benachrichtigungen schicken, z.B. wenn eine neue Klausur verfügbar ist?
          </div>

          <div class="w-full bg-white rounded-xl shadow-new mt-5 border border-new-yellow2">
            <setting label='Benachrichtigungen aktivieren' action='toggle' storeContainer='auth' storeID='toggleNotifications' storeWrapper='settings' />
          </div>
        </div>
        <!-- Benachrichtigungen -->

        <!-- Nickname -->
        <div v-else-if="step === 3">
          <div class="text-xl">Dein Nickname</div>

          <div class="bg-white rounded-xl shadow-new p-3 border border-new-yellow2 mt-5 text-base">
            Du kannst dir den Namen aussuchen, der für alle anderen Schüler im Ranking & bei Battles sichtbar ist.
          </div>

          <div class="bg-white rounded-xl shadow-soft w-full h-auto mt-2 px-5">
            <mySettingsItem settingName="Angezeigter Name" :settingValue="me?.userData?.data?.attributes?.nickname" :noBorder="true" :editable="true" @buttonClicked="openUserNicknameEdit()" />
          </div>
        </div>
        <!-- Nickname -->
        </transition>

        <div class="fixed right-0 bottom-0 p-5 md:p-12 md:w-1/2 md:mt-8 min-h-28 w-full bg-new-yellow border-t-2 border-new-yellow2 md:border-none">
          <customButton label='Weiter' :inactive="toggleDataPolicy ? false : true" @click="next"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Strapi
import updateUserNameMutation from '../profile/strapi/updateUserNameMutation.vue';

// Mixins
import iosVibrations from '../../mixins/iosVibrations.vue';
import detectNotch from '../../mixins/detectNotch.vue';
import fadeInOut from '../../mixins/animations/fadeInOut.vue';
import fcmMixin from '../../mixins/fcm.vue';
import pushNotificationsPermission from '../../mixins/pushNotificationsPermission.vue';

// Components
import tipTap from '../../symbols/tipTap.vue';
import darkBackground from '../../symbols/darkBackground.vue';
import infoOverlay from '../../symbols/infoOverlay.vue';
import customButton from '../../symbols/customButton.vue';
import setting from '../../symbols/setting.vue';
import mySettingsItem from '../profile/components/mySettingsItem.vue';
import editUserNickname from '../profile/components/editUserNickname.vue';


export default {
  name: 'onboarding',
  mixins: [iosVibrations, detectNotch, fadeInOut, fcmMixin, pushNotificationsPermission],
  components: {
    tipTap, darkBackground, infoOverlay, customButton, setting, mySettingsItem, editUserNickname, updateUserNameMutation,
  },

  data() {
    return {
      imagePath: process.env.VUE_APP_IMAGE,
      step: 1,
    };
  },

  computed: {
    dataPolicy() {
      return this.$store.state.auth.dataPolicy;
    },
    updateUserDataPolicy() {
      return this.$store.state.updateUserDataPolicy;
    },
    userDataId() {
      return this.$store.state.profile?.me?.userData?.data?.id;
    },
    version() {
      return this.$store.state.auth.dataPolicy.data.attributes.Version;
    },
    toggleDataPolicy() {
      return this.$store.state.auth.settings.toggleDataPolicy;
    },
    toggleNotifications() {
      return this.$store.state.auth.settings.toggleNotifications;
    },
    me() {
      return this.$store.state.profile.me;
    },
    onboardingCompleted() {
      return this.me?.userData?.data?.attributes?.onboardingCompleted;
    },
    updateUserDataCompleteOnboarding() {
      return this.$store.state.updateUserDataCompleteOnboarding;
    },
  },

  watch: {
    toggleNotifications() {
      if (this.toggleNotifications) {
        this.getPushPermission();
      }
    },
  },

  methods: {

    openUserNicknameEdit() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', 'editUserNickname');
    },

    next() {
      if (this.step === 1) {
        this.acceptDataPolicy();
      } else if (this.step === 2) {
        this.step += 1;
        this.iosVibrate(1);
      } else if (this.step === 3) {
        this.iosVibrate(1);
        this.completeOnboarding();
      }
    },

    acceptDataPolicy() {
      if (!this.toggleDataPolicy) {
        this.iosVibrate(1);
        this.$store.commit('toggleInfoOverlay', { value: true, index: 1 });
      } else if (this.onboardingCompleted) {
        this.finishOnboarding();
      } else {
        this.step += 1;
      }
    },

    completeOnboarding() {
      this.updateUserDataCompleteOnboarding({ userDataId: this.userDataId }).then((result) => {
        if (result.error) {
          console.log(result.error.message);
        } else {
          this.finishOnboarding();
        }
      });
    },

    finishOnboarding() {
      this.updateUserDataPolicy({ userDataId: this.userDataId, version: this.version }).then((result) => {
        if (result.error) {
          console.log(result.error.message);
        } else {
          this.$router.replace({ name: 'dashboard' });
        }
      });
    },
  },
};
</script>
