<script>
export default {
  name: 'pushNotificationsPermission',

  mounted() {
    window.checkNativePermission = (answer) => {
      this.checkPushPermissionAnswer(answer);
    };
  },

  methods: {

    checkPushPermission() {
      console.log('CHECKING PUSH PERMISSION');
      if (window.webkit && window.webkit.messageHandlers) {
        window.webkit.messageHandlers.checkPermission.postMessage('Checking Permission..');
      }
    },

    checkPushPermissionAnswer(answer) {
      console.log(answer);
      if (answer === 'false') {
        this.getPushPermission();
      }
    },

    getPushPermission() {
      if (window.webkit && window.webkit.messageHandlers) {
        window.webkit.messageHandlers.getPermission.postMessage('Getting Permission..');
      }
    },
  },
};
</script>
